<script setup lang="ts">
import { computed, ref } from "vue";
import { Autoplay, Grid, Navigation, Pagination } from "swiper/modules";
import type {
  PaginationOptions,
  Swiper as SwiperClass,
  SwiperOptions,
} from "swiper/types";
import { Swiper } from "swiper/vue";
import {
  responsiveClass,
  responsiveVariables,
} from "@magnit/core/src/utilities/helpers";
import type { IResponsiveOnly, IViewport } from "@magnit/core/types";
import VSliderDots from "@magnit/core/src/components/VSliderDots/VSliderDots.vue";
import ArrowIconRight from "@magnit/icons/src/assets/icons/arrow-right-corner-24.svg";
import ArrowIconLeft from "@magnit/icons/src/assets/icons/arrow-left-corner-24.svg";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import type { ISwiperOptions } from "~/typings/components/appSlider";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";

type INavigationVisible = IResponsiveOnly<boolean>;
enum Viewport {
  xs = 320,
  s = 360,
  m = 560,
  ml = 768,
  l = 960,
  xl = 1280,
}

const { default: defaultSlots } = useSlots();

interface ISliderProps {
  navigation?: boolean;
  pagination?: boolean | PaginationOptions;
  slidesPerView?: number | "auto";
  spaceBetween?: number;
  slidesOffsetAfter?: number;
  slidesOffsetBefore?: number;
  grid?: {
    rows?: number;
    fill?: "row" | "column";
  };
  loop?: boolean;
  autoplay?: boolean;
  breakpoints?: IResponsiveOnly<ISwiperOptions>;
}

const props = defineProps<ISliderProps>();
const emit = defineEmits<{
  slideChange: [value: SwiperClass];
  slideChangeByUser: [value: SwiperClass];
  slideClick: [value: SwiperClass, clickEvent: Event];
  navigationNext: [value: SwiperClass];
  navigationPrev: [value: SwiperClass];
  onSwiper: [value: SwiperClass];
}>();

const modules = [Autoplay, Grid, Navigation, Pagination];

const currentSlideIdx = ref<number>(0);
const swiperRef = ref<SwiperClass>();
const hasPagination = ref<boolean>(!!props.pagination);
const navigationHiddenRef = reactive({
  prev: true,
  next: true,
});
const bulletsLength = ref(0);
const autoplayProps = computed(
  () =>
    props.autoplay && {
      delay: 4000,
      disableOnInteraction: false,
    },
);

const paginationOptions = computed<PaginationOptions>(() => ({
  enabled:
    typeof props.pagination === "object"
      ? props.pagination.enabled
      : props.pagination,
  clickable: true,
  bulletClass: "swiper-pagination_pagination-bullet",
  bulletActiveClass: "swiper-pagination_pagination-bullet-active",
  ...(typeof props.pagination === "object" ? props.pagination : {}),
}));
const rootVariables = computed(() => {
  const gaps: IResponsiveOnly<string> = {};
  const offsets: IResponsiveOnly<string> = {};

  if (props.spaceBetween) {
    gaps.xs = `${props.spaceBetween}px`;
  }

  if (props.slidesOffsetBefore || props.slidesOffsetAfter) {
    offsets.xs = `${(props.slidesOffsetBefore || 0) + (props.slidesOffsetAfter || 0)}px`;
  }

  if (props.breakpoints) {
    for (const [key, value] of Object.entries(props.breakpoints)) {
      if (typeof value.spaceBetween !== "undefined") {
        gaps[key as IViewport] = `${value.spaceBetween}px`;
      }

      if (
        typeof value.slidesOffsetBefore !== "undefined" ||
        typeof value.slidesOffsetAfter !== "undefined"
      ) {
        offsets[key as IViewport] = `${
          (value.slidesOffsetBefore || 0) + (value.slidesOffsetAfter || 0)
        }px`;
      }
    }
  }

  return {
    ...responsiveVariables("--_g", gaps),
    ...responsiveVariables("--_so", offsets),
  };
});

const navigationClass = computed(() => {
  const navigation: INavigationVisible = {};

  if (props.navigation) {
    navigation.xs = props.navigation;
  }

  if (props.breakpoints) {
    for (const [key, value] of Object.entries(props.breakpoints)) {
      if (typeof value.navigation !== "undefined") {
        navigation[key as IViewport] = value.navigation;
      }
    }
  }

  return [...responsiveClass("slider-navigation_visible", navigation)];
});

const breakpoints = computed(() => {
  if (props.breakpoints) {
    const options: {
      [width: number]: ISwiperOptions;
    } = {};

    for (const [key, value] of Object.entries(props.breakpoints)) {
      options[Viewport[key as IViewport]] = value;
    }
    return options;
  }

  return undefined;
});

const updateSliderControls = (swiper: SwiperClass) => {
  const sliderWidth = swiper.wrapperEl.clientWidth ?? 0;
  const slidesCount = swiper.slides.length;
  const slideWidth = swiper.slides[0]?.clientWidth ?? 0;
  const isSlidesOverflowWindow = slidesCount * slideWidth >= sliderWidth;

  navigationHiddenRef.next = !props.loop && swiper?.isEnd;
  navigationHiddenRef.prev = !props.loop && swiper?.isBeginning;
  bulletsLength.value =
    (isSlidesOverflowWindow && swiper.pagination.bullets.length) || 0;

  if (isSlidesOverflowWindow && autoplayProps.value) {
    if (!swiper.autoplay.running) swiper.autoplay.start();
  } else {
    if (swiper.autoplay.running) swiper.autoplay.stop();
  }
};

const onSwiper = (swiper: SwiperClass) => {
  swiperRef.value = swiper;
  onSwiperSlideChange(swiper);
  emit("onSwiper", swiper);
};

const onSlidesUpdated = (swiper: SwiperClass) => {
  // при ассинхронном наполнении слайдера не всегда слайды успевают быть "получены" до инициализации свайпера
  // поэтому полагаемся на событие slidesUpdated от свайпера, чтобы знать когда конфигурация слайдов изменилась
  // (вроде-бы это самое близкое подходящее событие)
  // чтобы пересчитать состояние управляющих элементов (например показать/скрыть стрелки)
  updateSliderControls(swiper);
};

const onSwiperSlideChange = (swiper: SwiperClass) => {
  emit("slideChange", swiper);
  currentSlideIdx.value = swiper.realIndex;
  updateSliderControls(swiper);
};

const onSwiperClick = (swiper: SwiperClass, event: Event) => {
  emit("slideClick", swiper, event);
};

const onNavigationNextClick = () => {
  if (swiperRef.value) {
    swiperRef.value?.slideNext();
    emit("navigationNext", swiperRef.value);
  }
};

const onNavigationPrevClick = () => {
  if (swiperRef.value) {
    swiperRef.value?.slidePrev();
    emit("navigationPrev", swiperRef.value);
  }
};

const touchStartIndex = ref(0);
const onSwiperTouchStart = (swiper: SwiperClass) => {
  touchStartIndex.value = swiper.realIndex;
};
const onSwiperTouchEnd = (swiper: SwiperClass) => {
  /**
   * nextTick важен, иначе swiper.realIndex еще не поменялся
   */
  nextTick(() => {
    if (touchStartIndex.value !== swiper.realIndex) {
      emit("slideChangeByUser", swiper);
    }
  });
};
const onSliderBreakpoint = (
  _swiper: SwiperClass,
  breakpointOptions: SwiperOptions,
) => {
  if (typeof breakpointOptions.pagination === "object") {
    hasPagination.value = Boolean(breakpointOptions.pagination?.enabled);
  }
};

const handleSlideTo = (v: number) => {
  if (!swiperRef.value) return;
  if (props.loop) {
    swiperRef.value.slideToLoop(v);
  } else {
    swiperRef.value.slideTo(v);
  }
};

watch(
  () => defaultSlots,
  (next) => {
    if (swiperRef.value && next && next.length) {
      swiperRef.value?.updateSlides();
    }
  },
);
</script>

<template>
  <div class="slider-wrapper">
    <Swiper
      class="slider"
      :class="{ 'slider_with-pagination': hasPagination }"
      :modules="modules"
      :loop="props.loop"
      :autoplay="autoplayProps"
      :navigation="{
        enabled: props.navigation,
        nextEl: '.slider-navigation_next',
        prevEl: '.slider-navigation_prev',
      }"
      :pagination="paginationOptions"
      :space-between="props.spaceBetween"
      :slides-per-view="props.slidesPerView"
      :breakpoints="breakpoints"
      :style="rootVariables"
      :slides-offset-after="props.slidesOffsetAfter"
      :slides-offset-before="props.slidesOffsetBefore"
      @swiper="onSwiper"
      @slide-change="onSwiperSlideChange"
      @click="onSwiperClick"
      @touch-start="onSwiperTouchStart"
      @touch-end="onSwiperTouchEnd"
      @breakpoint="onSliderBreakpoint"
      @slides-updated="onSlidesUpdated"
    >
      <slot />
    </Swiper>
    <VButton
      theme="tertiary"
      rounded
      size="m"
      shadow
      class="slider-navigation slider-navigation_next"
      :class="[
        ...navigationClass,
        {
          'slider-navigation_hidden': navigationHiddenRef.next,
          'slider-navigation_paginated': hasPagination,
        },
      ]"
      aria-label="Следующий"
      @click="onNavigationNextClick"
    >
      <template #icon>
        <ArrowIconRight />
      </template>
    </VButton>
    <VButton
      theme="tertiary"
      rounded
      size="m"
      shadow
      class="slider-navigation slider-navigation_prev"
      :class="[
        ...navigationClass,
        {
          'slider-navigation_hidden': navigationHiddenRef.prev,
          'slider-navigation_paginated': hasPagination,
        },
      ]"
      aria-label="Предыдущий"
      @click="onNavigationPrevClick"
    >
      <template #icon>
        <ArrowIconLeft />
      </template>
    </VButton>
    <VSliderDots
      v-if="hasPagination && bulletsLength > 1"
      v-model="currentSlideIdx"
      :count="bulletsLength"
      size="l"
      :visible-count="bulletsLength"
      class="slider-pagination"
      @update:model-value="handleSlideTo"
    />
  </div>
</template>

<style lang="postcss" scoped>
.slider-wrapper {
  position: relative;

  --pagination-block-height_half: 24px;
}

.slider-navigation {
  position: absolute;
  top: 50%;
  z-index: var(--pl-z-index-level1);
  display: none;
  background: var(--pl-button-background-invert-primary-default);

  &:hover,
  &:visited {
    background: var(--pl-button-background-invert-primary-default);
  }

  &_paginated {
    top: calc(50% - var(--pagination-block-height_half));
  }

  &_prev {
    left: 0;
    transform: translate(-50%, -50%);
  }

  &_next {
    right: 0;
    transform: translate(50%, -50%);
  }

  &_visible {
    display: flex;
  }

  &_hidden {
    display: none;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 1px;
}

.slider-pagination {
  z-index: var(--pl-z-index-level1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & :deep(.pl-slider-dots__dot:last-child) {
    margin-right: 0;
  }
}

.slider {
  --_g: 0;
  --_g-s: var(--_g);
  --_g-m: var(--_g-s);
  --_g-ml: var(--_g-m);
  --_g-l: var(--_g-ml);
  --_g-xl: var(--_g-l);
  --_so: 0;
  --_so-s: var(--_so);
  --_so-m: var(--_so-s);
  --_so-ml: var(--_so-m);
  --_so-l: var(--_so-ml);
  --_so-xl: var(--_so-l);
  --pl-slider-gap: var(--_g);
  --pl-slider-offset: var(--_so);

  width: 100%;

  &_with-pagination {
    &:has(.swiper-pagination-lock) {
      padding-bottom: 0;
    }
  }
}

@each $breakpoint in s, m, ml, l, xl {
  @media (--pl-viewport-from-$(breakpoint)) {
    .slider {
      --pl-slider-gap: var(--_g-$(breakpoint));
      --pl-slider-offset: var(--_so-$(breakpoint));
    }

    .slider-navigation {
      &_visible-true-$(breakpoint) {
        display: flex;
      }

      &_visible-false-$(breakpoint) {
        display: none;
      }

      &_hidden {
        display: none;
      }
    }
  }
}
</style>
