<script setup lang="ts">
import { computed, useAttrs } from "vue";
import { SwiperSlide as SwiperSlideOriginal } from "swiper/vue";
import { responsiveClass } from "@magnit/core/src/utilities/helpers";
import type { IResponsive } from "@magnit/core/types";

export type ISlideSize = IResponsive<
  1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto"
>;

interface ISliderProps {
  size?: ISlideSize;
}
defineOptions({
  name: "SwiperSlide",
  inheritAttrs: false,
});
const props = defineProps<ISliderProps>();
const attrs = useAttrs();

const rootClass = computed(() => {
  return [...responsiveClass("slider-slide_size", props.size), attrs.class];
});
</script>

<template>
  <SwiperSlideOriginal class="slider-slide" :class="rootClass">
    <slot />
  </SwiperSlideOriginal>
</template>

<style lang="postcss" scoped>
.slider-slide {
  &&_size-auto {
    width: auto;
  }

  @each $size in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 {
    &&_size-$(size) {
      width: calc(
        ((100% - var(--pl-slider-offset)) / (12 / $(size))) -
          (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / $(size))))
      );
    }
  }
}

@each $breakpoint in s, m, ml, l, xl {
  @media (--pl-viewport-from-$(breakpoint)) {
    .slider-slide {
      &&_size-auto-$(breakpoint) {
        width: auto;
      }

      @each $size in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 {
        &&_size-$(size)-$(breakpoint) {
          width: calc(
            ((100% - var(--pl-slider-offset)) / (12 / $(size))) -
              (var(--pl-slider-gap) - (var(--pl-slider-gap) / (12 / $(size))))
          );
        }
      }
    }
  }
}
</style>
